
import { defineAsyncComponent, defineComponent, ref } from 'vue';
import { isAppleDevice } from '@/utils/general-utils';

export default defineComponent({
  name: 'ModalClientDanRegional',
  props: {
    isVisible: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: {
    NewModal: defineAsyncComponent(
      () => import('@/components/shared/NewModal.vue')
    ),
  },
  emits: ['close'],
  setup(_props, { emit }) {
    const iPhoneLink = ref<unknown>({});

    const close = () => {
      emit('close');
    };

    const redirectPse = (): void => {
      const processUrl = 'https://pagos.refinancia.com.co:4443/placetopay/web/';
      isAppleDevice(iPhoneLink.value as HTMLLinkElement, processUrl);
    };

    const redirectPqr = (): void => {
      const processUrl = 'https://refinancia.co/contactenos/';
      isAppleDevice(iPhoneLink.value as HTMLLinkElement, processUrl);
    };

    return { close, redirectPse, redirectPqr, iPhoneLink };
  },
});
